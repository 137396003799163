@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url("./sf-pro-display-regular.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: "normal";
  font-weight: 500;
  src: url("./sf-prop-display-medium.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: "normal";
  font-weight: 600;
  src: url("./sf-pro-display-semibold.woff") format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: "normal";
  font-weight: 700;
  src: url("./sf-pro-display-bold.woff") format("woff");
}
